
import { inject } from '@/inversify';
import { Component, Vue } from 'vue-property-decorator';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import UserViewService, { UserViewServiceS } from '@/modules/user/user-view.service';

@Component
export default class RankingHeader extends Vue {
    @inject(UserServiceS) private userService!: UserService;
    @inject(HotelsServiceS) private hotelsService!: HotelsService;
    @inject(UserViewServiceS) private userViewService!: UserViewService;

    get hotelName() {
        const { currentHotelId } = this.userService;

        if (!currentHotelId) {
            return null;
        }
        return this.hotelsService.getHotelName(currentHotelId);
    }

    get isUnderChainOrCluster() {
        return this.userService.isChainOrClusterUser;
    }

    toCluster() {
        this.userViewService.goToClusterPage();
    }
}
